import React, { useEffect, useState } from 'react'

import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Chip, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core'

import * as yup from 'yup';
import { useField, useFormik, useFormikContext } from 'formik';
import { getAlumnosRegistradosBuscar } from '../../services/alumnos';
import { useSelector } from 'react-redux';
import { selectUsuarioSeleccionado } from '../../redux/reducer/loginReducer';
import { Stack } from '@mui/material';
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FaceIcon from '@mui/icons-material/Face';

const FormObserver = () => {
    const { values } = useFormikContext() ?? {};
  
    useEffect(() => {
      console.log("FormObserver::values", values);
    }, [values]);
  
    return null;
  };

const DialogModalSearch = ( {openModalSearch, setOpenModalSearch} ) => {
    const history = useHistory()

    const [alumnos, setAlumnos] = useState([]);
    const [loadling, setLoadling] = useState(false)

    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    const [initialValues, setInitialValues] = useState({
      telefono: '',
      correo_electronico: '',
      id: '',
    })
    const validationSchema = yup.object({
        
        // nombre: yup.string().required('Nombre es requerido'),
        correo_electrnico: yup.string().email() //.required('Correo es requerido'),
        
    });
      
    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: (values, actions) => {
        setLoadling(true)
        if(values.telefono === '' && values.correo_electronico === '' && values.id === "" ) {
          actions.setFieldError('telefono', 'Telefono, correo electrónico o ID son obligatorios')
          actions.setFieldError('correo_electronico', 'Telefono, correo electrónico o ID son obligatorios')
          actions.setFieldError('id', 'Telefono, correo electrónico o ID son obligatorios')
          return;
        }

        getAlumnosRegistradosBuscar({usuario_id: usuarioSeleccionado.id, telefono: values.telefono, correo_electronico: values.correo_electronico, id: values.id}).then( (response) => {
          
            setAlumnos(response.alumnos)
            setLoadling(false)

        } )  

      },
    })

    const handleKeyUpForm = (values) => {
        setLoadling(true)

        if(values.telefono.length > 3){
            getAlumnosRegistradosBuscar({usuario_id: usuarioSeleccionado.id, telefono: values.telefono, correo_electronico: values.correo_electronico, id: values.id}).then( (response) => {
            
                setAlumnos(response?.alumnos)
                setLoadling(false)

            } ) 
        } else {
            setAlumnos([])
        }
    }

    return (
        <Dialog open={openModalSearch} onClose={() => setOpenModalSearch(false)} maxWidth={'md'}>
                
            <DialogTitle>Buscar alumno</DialogTitle>
            <DialogContent style={{minHeight: '150px'}}>
                
            <form 
                noValidate 
                method="POST" 
                enctype="multipart/form-data" 
                autoComplete="off" 
                onKeyUp={ () => handleKeyUpForm(formik.values) } 
                onSubmit={ formik.handleSubmit}
            >

                <Grid container  spacing={2} style={{flex: 1, width: '100%', paddingLeft: 15}}>
                    <Grid item xs={8} md={2}>
                        <TextField
                        id="id"
                        name='id'
                        label="ID"
                        placeholder="al menos 3 caracteres"
                        value={formik.values.id}
                        onChange={ formik.handleChange }
                        variant="outlined"
                        fullWidth
                        error={formik.touched.id && Boolean(formik.errors.id)}
                        helperText={formik.touched.id && formik.errors.id}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        />
                    </Grid>
                    <Grid item xs={8} md={5}>
                        <TextField
                        id="telefono"
                        name='telefono'
                        label="Telefono"
                        placeholder="al menos 3 caracteres"
                        value={formik.values.telefono}
                        onChange={ formik.handleChange }
                        variant="outlined"
                        fullWidth
                        error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                        helperText={formik.touched.telefono && formik.errors.telefono}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        />
                    </Grid>
                    <Grid item xs={8} md={5}>
                        <TextField
                        id="correo_electronico"
                        name='correo_electronico'
                        label="Correo electrónico"
                        placeholder="al menos 3 caracteres"
                        value={formik.values.correo_electronico}
                        onChange={ formik.handleChange }
                        variant="outlined"
                        fullWidth
                        error={formik.touched.correo_electronico && Boolean(formik.errors.correo_electronico)}
                        helperText={formik.touched.correo_electronico && formik.errors.correo_electronico}
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        />
                    </Grid>
                    <Grid item xs={8} md={2}>
                        <Button 
                        fullWidth
                        variant="contained" 
                        color="primary" 
                        type="submit"
                        size='large'
                        style={{height: 55}}
                        >
                        Buscar
                        </Button>
                    </Grid>
                </Grid>

            </form>

            <Grid container spacing={2} style={{paddingLeft: 15, paddingRight: 15}}>
                {alumnos.map( (alumno) => {
                return (
                    <Grid key={alumno.id} item xs={10} sm={6} lg={6}>
                        <Card>
                            <CardActionArea onClick={ () => {
                                
                                history.push('/alumnos/detalle/' + alumno.id)
                                history.go(0)
                                setOpenModalSearch(false)

                            } }>
                                <CardHeader title={alumno.nombre} />
                                <CardContent>
                                    <Stack direction="column">
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            <Box><b>Telefono:</b> {alumno.telefono}</Box>
                                            <Box><b>Correo electrónico:</b> {alumno.correo_electronico}</Box>
                                            <Box><b>Estado:</b> {alumno.status_nombre}</Box>
                                            <Box><b>Comunidad:</b> {alumno.comunidad_autonoma_nombre}</Box>
                                            <Box><b>Especialidad:</b> {alumno.especialidad_nombre}</Box>
                                            <Box style={{marginTop: 20}}><Chip icon={<FaceIcon />} size="small" label={alumno.comercial_nombre} variant="outlined"/></Box>
                                        </Typography>
                                    </Stack>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={ () => {
                                        history.push('/alumnos/detalle/' + alumno.id)
                                        history.go(0)
                                        setOpenModalSearch(false)
                                    } }>
                                        Abrir Ficha
                                    </Button>
                                </CardActions>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )
                })}
                </Grid>


            </DialogContent>
                    
        </Dialog>
    )
}

export default DialogModalSearch
