import React, { useEffect } from "react";
import { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import LoadingComponent from "../../../../components/Loading";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../../redux/reducer/loginReducer";
import EmptyComponent from "../../../../components/EmptyContent";
import TituloPagina from "../../../../components/Titulos/TituloPagina";
import {
  getAlumnosProductosSeguimiento,
  getIndicadoresVentas,
} from "../../../../services/alumnos";
import {
  DataGridPro,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Box, Card, CardContent, Grid, Paper, Typography } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import BarChartIcon from "@material-ui/icons/BarChart";
import PivotTableUI from "react-pivottable/PivotTableUI";
import { Divider } from "@mui/material";

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "80px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "90vh",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    width: "100%",
    flexDirection: "column",
    textAlign: "center",
  },
  emptyImage: {
    width: "100%",
  },
  rootFilter: {
    padding: theme.spacing(0.5, 0.5, 0),
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  textFieldFilter: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    margin: theme.spacing(1, 0.5, 1.5),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(0.5),
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  enlace: {
    color: theme.palette.link.main,
    textDecoration: "none",
  },
}));

export default function AlumnosProductosSeguimientoContent() {
  const classes = useStyles();

  // const [alumnosProductosSeguimiento, setAlumnosProductosSeguimiento] =
  //   useState([]);
  // const [productosSeguimientoComercial, setProductosSeguimientoComercial] =
  //   useState([]);
  const [ventasSeguimientoComercial, setVentasSeguimientoComercial] =
    useState([]);
  const [ventasComercial, setVentasComercial] =
    useState([]);
  const [ventasSeguimientoComercialAndaluciaCYL, setVentasSeguimientoComercialAndaluciaCYL] =
    useState([]);
  const [loadingProductosSeguimientoComercial, setLoadingProductosSeguimientoComercial] = useState(false);
  const [loadingVentasSeguimientoComercial, setLoadingVentasSeguimientoComercial] = useState(false);
  const [loadingNumRepetidores, setLoadingNumRepetidores] = useState(false);
  const [loadingNumNuevos, setLoadingNumNuevos] = useState(false);
  const [numRepetidores, setNumRepetidores] = useState(0);
  const [numNuevos, setNumNuevos] = useState(0);

  const [loadingMensaje, setLoadingMensaje] = useState("");

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  function filtrarComunidades(data, comunidadesAQuitar) {
    // Obtener los distintos nombres de comunidades autónomas del array original
    const comunidadesAutonomas = [...new Set(data.map(item => item.comunidad_autonoma_nombre))];
    
    // Crear el objeto resultado
    const resultado = {};
    
    // Llenar el objeto resultado con las comunidades que no están en comunidadesAQuitar
    comunidadesAutonomas.forEach(comunidad => {
        if (!comunidadesAQuitar.includes(comunidad)) {
            resultado[comunidad] = true;
        }
    });
    
    return resultado;
  }
  function filtrarEspecialidades(data, especialidadesAQuitar) {
    // Obtener los distintos nombres de comunidades autónomas del array original
    const especialidades = [...new Set(data.map(item => item.especialidad_nombre))];
    
    // Crear el objeto resultado
    const resultado = {};
    
    // Llenar el objeto resultado con las comunidades que no están en comunidadesAQuitar
    especialidades.forEach(especialidad => {
        if (!especialidadesAQuitar.includes(especialidad)) {
            resultado[especialidad] = true;
        }
    });
    
    return resultado;
  }
  function filtrarProductos(data, productosAQuitar) {
    // Obtener los distintos nombres de comunidades autónomas del array original
    const productos = [...new Set(data.map(item => item.producto_categoria))];
    
    // Crear el objeto resultado
    const resultado = {};
    
    // Llenar el objeto resultado con las comunidades que no están en comunidadesAQuitar
    productos.forEach(producto => {
        if (!productosAQuitar.includes(producto)) {
            resultado[producto] = true;
        }
    });
    
    return resultado;
  }
  function filtrarOposiciones(data, oposicionesAQuitar) {
    // Obtener los distintos nombres de comunidades autónomas del array original
    const oposiciones = [...new Set(data.map(item => item.oposicion_nombre))];
    
    // Crear el objeto resultado
    const resultado = {};
    
    // Llenar el objeto resultado con las comunidades que no están en comunidadesAQuitar
    oposiciones.forEach(oposicion => {
        if (!oposicionesAQuitar.includes(oposicion)) {
            resultado[oposicion] = true;
        }
    });
    
    return resultado;
  }

  useEffect(() => {
    setLoadingProductosSeguimientoComercial(true);
    setLoadingMensaje("Cargando productos vendidos");

    const usuario_id = usuarioSeleccionado.id;

    // getAlumnosProductosSeguimiento({ usuario_id }).then((response) => {
    //   setLoading(false);

    //   response &&
    //     setAlumnosProductosSeguimiento(response.alumnos_productos_seguimiento);
    // });

    // getIndicadoresVentas({usuario_id: usuario_id, nombre_indicadores: "productos_seguimiento_comercial"}).then((response) => {
    //   setLoadingProductosSeguimientoComercial(false);

    //   response && setProductosSeguimientoComercial(response.resultado);

    // });
    getIndicadoresVentas({usuario_id: usuario_id, nombre_indicadores: "ventas_seguimiento_comercial"}).then((response) => {
      setLoadingVentasSeguimientoComercial(false);

      response && setVentasSeguimientoComercial(response.resultado);

      console.log(response.resultado)
    });
    
    getIndicadoresVentas({usuario_id: usuario_id, nombre_indicadores: "ventas_seguimiento_comercial_andalucia_cyl"}).then((response) => {
      setLoadingVentasSeguimientoComercial(false);

      response && setVentasSeguimientoComercialAndaluciaCYL(response.resultado);

    });
    getIndicadoresVentas({usuario_id: usuario_id, nombre_indicadores: "num_repetidores"}).then((response) => {
      setLoadingNumRepetidores(false);

      response && setNumRepetidores(response.resultado);
    });
    getIndicadoresVentas({usuario_id: usuario_id, nombre_indicadores: "num_nuevos"}).then((response) => {
      setLoadingNumNuevos(false);

      response && setNumNuevos(response.resultado);
    });
    getIndicadoresVentas({usuario_id: usuario_id, nombre_indicadores: "ventas_comercial"}).then((response) => {
      setLoadingNumNuevos(false);

      response && setVentasComercial(response.resultado);
    });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                           Definicion de columnas                           */
  /* -------------------------------------------------------------------------- */
  const columnas = [
    { field: "id", headerName: "Alumno ID" },
    { field: "alumno_nombre", headerName: "Alumno", minWidth: 280, flex: 1 },
    { field: "es_23_24", headerName: "23/24", minWidth: 100 },
    { field: "es_24_25", headerName: "24/25", minWidth: 100 },
    { field: "es_25_26", headerName: "25/26", minWidth: 100 },
    { field: "es_acceso_tutea", headerName: "Tutea", minWidth: 100 },
    { field: "es_programa_start", headerName: "Start", minWidth: 100 },
    { field: "es_programa_fast", headerName: "Fast", minWidth: 100 },
    {
      field: "es_programacion_secundaria",
      headerName: "Programación",
      minWidth: 100,
    },
    { field: "plaza", headerName: "plaza", minWidth: 100 },
    {
      field: "comunidad_autonoma_nombre",
      headerName: "Comunidad",
      minWidth: 250,
    },
    { field: "especialidad_nombre", headerName: "Especialidad", minWidth: 250 },
    { field: "oposicion_nombre", headerName: "Oposicion", minWidth: 150 },
    { field: "alumno_estado", headerName: "Estado", minWidth: 150 },
  ];
  /* -------------------------------------------------------------------------- */
  /*                                  Contenido                                 */
  /* -------------------------------------------------------------------------- */
  function QuickSearchToolbar(props) {
    const classes = useStyles();

    return (
      <div className={classes.rootFilter}>
        <div>
          <GridToolbarFilterButton />
          <GridToolbarExport />
        </div>
      </div>
    );
  }
  class PivotTableUISmartWrapper extends React.PureComponent {

    constructor(props) {
      super(props);
      this.state = props;
      this.datos = props.datos;
      this.rows = props.rows;
      this.cols = props.cols;
      this.valueFilter = props.valueFilter
    }
  
    render() {
      return (
        <>
              
            <div style={{ width: '100%', padding:"0 15px" }}>
                <PivotTableUI
                    data={this.datos}
                    onChange={s => this.setState(s)}
                    rows={this.rows}
                    cols={this.cols}
                    valueFilter={this.valueFilter}
                    {...this.state}
                />
            </div>
            <br/>
            <br/>
            
          </>
      );
  }
}
  const Contenido = () => {
    return (
      <>
        <div style={{ padding: "0 15px", display: "flex", gap: 20 }}>
          {
          loadingNumRepetidores ? (
            <LoadingComponent />
          ) : (
            <CardIndicador
              titulo={"Alumnos repetidores"}
              valor={numRepetidores}
              descripcion={
                "((curso_23_24) = 1 o (es_fast) = 1) y ( (curso_24_25)=1 o (curso_25_26)=1 o (es_start)=1 o (es_programacion_secundaria)=1 o (es_tutea)=1)"
              }
            />
          )}

          {
          loadingNumNuevos ? 
          <LoadingComponent />
          :
          <CardIndicador
            titulo={"Alumnos nuevos"}
            valor={numNuevos}
            descripcion={"((curso_23_24) = 0 y (es_fast) = 0) y ((curso_24_25)=1 o (curso_25_26)=1 o (es_start)=1 o (es_programacion_secundaria)=1)"}
          />}
        </div>

        <div style={{height: 40}} />
        <Box mb={3} mx={2} style={{borderBottom: "1px solid black"}}>
          <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >VENTAS</Typography>
        </Box>
        {ventasComercial ? (
          <>
            <PivotTableUISmartWrapper 
            datos={ventasComercial} 
            rows={['comunidad_autonoma_nombre']}
            cols={['producto_nombre','alumno_status_nombre']}
            valueFilter={{
              // "comunidad_autonoma_nombre": filtrarComunidades(ventasComercial,["Andalucía", "Castilla y León"]),
              // "oposicion_nombre": filtrarOposiciones(ventasComercial,["Infantil", "Primaria"]),
              "producto_categoria": filtrarProductos(ventasComercial,["curso_24_25","curso_25_26","start","programacion_secundaria"])
            }}
            />
          </>
        ) : (
          <EmptyComponent mensaje="No hay datos para mostrar" />
        )}
        
        <div style={{height: 40}} />
        <Box mb={3} mx={2} style={{borderBottom: "1px solid black"}}>
          <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >PRIMARIA E INFANTIL EN ANDALUCÍA Y EN CASTILLA Y LEON</Typography>
        </Box>
        {ventasSeguimientoComercial ? (
          <>
            <PivotTableUISmartWrapper 
            datos={ventasSeguimientoComercial} 
            rows={['comunidad_autonoma_nombre','oposicion_nombre']}
            cols={['producto_categoria','es_repetidor']}
            valueFilter={{
              "comunidad_autonoma_nombre": filtrarComunidades(ventasSeguimientoComercial,["Andalucía", "Castilla y León"]),
              "oposicion_nombre": filtrarOposiciones(ventasSeguimientoComercial,["Infantil", "Primaria"]),
              "producto_categoria": filtrarProductos(ventasSeguimientoComercial,["curso_24_25"])
            }}
            />
          </>
        ) : (
          <EmptyComponent mensaje="No hay datos para mostrar" />
        )}

        {ventasSeguimientoComercial ? (
          <>
            <PivotTableUISmartWrapper 
            datos={ventasSeguimientoComercial} 
            rows={['comunidad_autonoma_nombre','oposicion_nombre']}
            cols={['producto_categoria','es_repetidor']}
            valueFilter={{
              "comunidad_autonoma_nombre": filtrarComunidades(ventasSeguimientoComercial,["Galicia", "Madrid"]),
              "oposicion_nombre": filtrarOposiciones(ventasSeguimientoComercial,["Infantil", "Primaria"]),
              "producto_categoria": filtrarProductos(ventasSeguimientoComercial,["curso_24_25"])
            }}
            />
          </>
        ) : (
          <EmptyComponent mensaje="No hay datos para mostrar" />
        )}

        <Box mb={3} mx={2} style={{borderBottom: "1px solid black"}}>
          <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >PROGRAMACIÓN SECUNDARIA</Typography>
        </Box>
        {ventasSeguimientoComercial ? (
          <>
            <PivotTableUISmartWrapper 
            datos={ventasSeguimientoComercial} 
            rows={['oposicion_nombre',"especialidad_nombre"]}
            cols={['producto_categoria']}
            valueFilter={{
              "oposicion_nombre": filtrarOposiciones(ventasSeguimientoComercial,["Secundaria"]),
              "producto_categoria": filtrarProductos(ventasSeguimientoComercial,["programacion_secundaria"])
            }}
            />
          </>
        ) : (
          <EmptyComponent mensaje="No hay datos para mostrar" />
        )}
        
        <Box mb={3} mx={2} style={{borderBottom: "1px solid black"}}>
          <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >PREPARACIÓN COMPLETA SECUNDARIA</Typography>
        </Box>
        {ventasSeguimientoComercial ? (
          <>
            <PivotTableUISmartWrapper 
            datos={ventasSeguimientoComercial} 
            rows={['oposicion_nombre',"especialidad_nombre"]}
            cols={['producto_categoria']}
            valueFilter={{
              "oposicion_nombre": filtrarOposiciones(ventasSeguimientoComercial,["Secundaria"]),
              "producto_categoria": filtrarProductos(ventasSeguimientoComercial,["curso_23_24", "curso_24_25"])
            }}
            />
          </>
        ) : (
          <EmptyComponent mensaje="No hay datos para mostrar" />
        )}
        
        <Box mb={3} mx={2} style={{borderBottom: "1px solid black"}}>
          <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >START (Alumnos nuevos de cara a 2026) Y TUTEA (Antiguos alumnos que siguen con la app abierta)</Typography>
        </Box>
        {ventasSeguimientoComercial ? (
          <>
            <PivotTableUISmartWrapper 
            datos={ventasSeguimientoComercial} 
            rows={['oposicion_nombre',"especialidad_nombre"]}
            cols={['producto_categoria','comunidad_autonoma_nombre']}
            valueFilter={{
              "oposicion_nombre": filtrarOposiciones(ventasSeguimientoComercial,["Primaria","Infantil"]),
              "producto_categoria": filtrarProductos(ventasSeguimientoComercial,["start", "tutea",])
            }}
            />
          </>
        ) : (
          <EmptyComponent mensaje="No hay datos para mostrar" />
        )}
        {/* {ventasSeguimientoComercial ? (
          <>
            <PivotTableUISmartWrapper 
            datos={ventasSeguimientoComercial} 
            rows={['oposicion_nombre',"especialidad_nombre"]}
            cols={['producto_categoria','comunidad_autonoma_nombre','es_repetidor']}
            valueFilter={{
              "oposicion_nombre": filtrarOposiciones(ventasSeguimientoComercial,["Primaria","Infantil"]),
              "producto_categoria": filtrarProductos(ventasSeguimientoComercial,["fast","curso_25_26"]),
              'es_repetidor': ["1"]
            }}
            />
          </>
        ) : (
          <EmptyComponent mensaje="No hay datos para mostrar" />
        )} */}
        



        {/* <Divider style={{marginTop: 30, marginBottom: 30}}/>

        {productosSeguimientoComercial ? (
          <>
            <Box mb={3} mx={2} style={{borderBottom: "1px solid black"}}>
                <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >Productos - Seguimiento comercial</Typography>
            </Box>

            <PivotTableUISmartWrapper 
            datos={productosSeguimientoComercial} 
            rows={['comunidad_autonoma_nombre']}
            cols={[]}
            />

            <Box mb={3} mx={2} style={{borderBottom: "1px solid black"}}>
                <Typography variant="h5" component="h3" gutterBottom style={{fontWeight: 500}} >Ventas - Seguimiento comercial</Typography>
            </Box>
            <PivotTableUISmartWrapper 
            datos={ventasSeguimientoComercial} 
            rows={['producto_categoria']}
            cols={[]} />
          </>
        ) : (
          <EmptyComponent mensaje="No hay datos para mostrar" />
        )} */}
      </>
    );
  };

  const CardIndicador = ({ titulo, valor, descripcion }) => {
    return (
      <Card elevation={5} style={{ width: "100%" }}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item style={{ display: "flex", width: "100%" }}>
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  marginRight: 2,
                  display: "flex",
                  borderRadius: "50%",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#0B314E",
                  // backgroundColor: alpha(C, 0.2),
                }}
              >
                <BarChartIcon fontSize="large" style={{ color: "#ffffff" }} />
              </Box>
              <Box
                style={{
                  backgroundColor: red,
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography color="textSecondary" variant="h5">
                  {titulo}
                </Typography>
                <Typography variant="h4">{valor}</Typography>
              </Box>
            </Grid>
            <Grid style={{ paddingTop: "20px" }}>
              <Typography color="textSecondary" variant="body2">
                {descripcion}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <div className={classes.container}>
      <Contenido />
    </div>
  );
}
