import React from 'react';
// import "../../assets/css/master.css";

import TemplateDashboard from "../../../components/TemplateDashboard";

import {
    selectUsuarioSeleccionado,
} from "../../../redux/reducer/loginReducer";
import { useSelector } from 'react-redux';
import AlumnosReservaContent from './components/AlumnosReservaContent';

const AlumnosReservasListado = () => {
    const usuarioSeleccionado = useSelector(state => {
        return selectUsuarioSeleccionado(state);
    });

    return (
        <>

            <TemplateDashboard>
                { 
                    <AlumnosReservaContent />
                }
            </TemplateDashboard>

        </>
    )
};

export default AlumnosReservasListado;