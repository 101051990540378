import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import * as yup from "yup";
import { useField, useFormik, useFormikContext } from "formik";
import { getAlumnosRegistradosBuscar, updateMotivoPerdida } from "../../../services/alumnos";
import { useSelector } from "react-redux";
import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import { Stack } from "@mui/material";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import FaceIcon from "@mui/icons-material/Face";
import { getListaDetalle } from "../../../services/lista_detalle";

// const FormObserver = () => {
//     const { values } = useFormikContext() ?? {};

//     useEffect(() => {
//       console.log("FormObserver::values", values);
//     }, [values]);

//     return null;
//   };

const DialogModalMotivoPerdida = ({ openModal, setOpenModal, alumnoId }) => {
  const history = useHistory();

  // const [alumnos, setAlumnos] = useState([]);
  const [loadling, setLoadling] = useState(false);

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });

  const [motivosPerdida, setMotivosPerdida] = useState([]);

  const [initialValues, setInitialValues] = useState({
    motivo_perdida_id: "",
  });
  const validationSchema = yup.object({
    motivo_perdida_id: yup.mixed().required("Obligatorio"),
  });

  useEffect(() => {
    const usuario_id = usuarioSeleccionado.id;

    // Motivos perdida
    getListaDetalle({ usuario_id: usuario_id, lista_id: 18 }).then(
      (response) => {
        response.lista_detalle && setMotivosPerdida(response.lista_detalle);
      }
    );
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {

        values.alumnoId = alumnoId;

        updateMotivoPerdida({usuario_id: usuarioSeleccionado.id, alumno_id: alumnoId, motivo_perdida_id: values.motivo_perdida_id}).then( (response) => {
            history.push('/alumnos/detalle/' + alumnoId)
            history.go(0)
            setOpenModal(false);
        } )

        // if(values.telefono === '' && values.correo_electronico === '' && values.id === "" ) {
      //   actions.setFieldError('telefono', 'Telefono, correo electrónico o ID son obligatorios')
      //   actions.setFieldError('correo_electronico', 'Telefono, correo electrónico o ID son obligatorios')
      //   actions.setFieldError('id', 'Telefono, correo electrónico o ID son obligatorios')
      //   return;
      // }

      // getAlumnosRegistradosBuscar({usuario_id: usuarioSeleccionado.id, telefono: values.telefono, correo_electronico: values.correo_electronico, id: values.id}).then( (response) => {

      //     setAlumnos(response.alumnos)
      //     setLoadling(false)

      // } )
    },
  });

  // const handleKeyUpForm = (values) => {
  //     setLoadling(true)

  //     if(values.telefono.length > 3){
  //         getAlumnosRegistradosBuscar({usuario_id: usuarioSeleccionado.id, telefono: values.telefono, correo_electronico: values.correo_electronico, id: values.id}).then( (response) => {

  //             setAlumnos(response?.alumnos)
  //             setLoadling(false)

  //         } )
  //     } else {
  //         setAlumnos([])
  //     }
  // }

  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)}>
      <DialogTitle>Motivo de la pérdida</DialogTitle>
      <DialogContent style={{ minHeight: "150px", width: "600px" }}>
        <form
          noValidate
          method="POST"
          enctype="multipart/form-data"
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Grid
            container
            spacing={2}
            style={{ flex: 1, width: "100%", paddingLeft: 15 }}
          >
            <Grid item xs={12}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{}}
                error={
                  formik.touched.motivo_perdida_id &&
                  Boolean(formik.errors.motivo_perdida_id)
                }
              >
                <InputLabel id="motivo_perdida_id-label">
                  Motivo pérdida
                </InputLabel>
                <Select
                  labelId="motivo_perdida_id-label"
                  id="motivo_perdida_id"
                  name="motivo_perdida_id"
                  label="Motivo pérdida"
                  fullWidth
                  value={formik.values.motivo_perdida_id}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.motivo_perdida_id &&
                    Boolean(formik.errors.motivo_perdida_id)
                  }
                >
                  {motivosPerdida &&
                    motivosPerdida.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.nombre}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText>
                  {formik.touched.motivo_perdida_id &&
                    formik.errors.motivo_perdida_id}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" color="primary" onClick={() => setOpenModal(false)}>
                Cancelar
              </Button>
              <Button variant="contained" color="primary" type="submit" style={{marginLeft: 10}}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DialogModalMotivoPerdida;
