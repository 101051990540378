import React, { useEffect } from "react";
import { useState } from "react";

import { useSelector } from "react-redux";

import { selectUsuarioSeleccionado } from "../../../redux/reducer/loginReducer";
import {
  getAlumnosPendientesPagoPrimeraCuota,
  getPlanificacionLlamadas,
} from "../../../services/alumnos";
import {
  DataGridPro,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import useDataGridFilterPersist from "../../../hooks/useDataGridFilterPersist";
import moment from 'moment/moment';
import Moment from 'react-moment';
import TemperaturaAlumno from "../../../components/TemperaturaAlumno";
import AddIcon from '@material-ui/icons/Add';
import { CardActions, CardContent, Chip, Stack } from "@mui/material";
import { red } from "@mui/material/colors";

/* -------------------------------------------------------------------------- */
/*                                   Estilos                                  */
/* -------------------------------------------------------------------------- */
export default function PlanificacionLlamadasContent() {
  // const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [loadingMensaje, setLoadingMensaje] = useState("");
  const [leadsComentarios, setLeadsComentarios] = useState([]);
  const [leadsPrimariaInfantil, setLeadsPrimariaInfantil] = useState([]);
  const [leadsSecundaria, setLeadsSecundaria] = useState([]);
  const [leadsValidos, setLeadsValidos] = useState([]);
  const [leadsContactadosHoy, setLeadsContactadosHoy] = useState([]);
  const [leadsPlanificados, setLeadsPlanificados] = useState([]);
  const [leadsPendientesPago, setLeadsPendientesPago] = useState([]);
  const [alumnosPendientesPago, setAlumnosPendientesPago] = useState([])
  const [leadsCurso, setLeadsCurso] = useState([]);
  const [demosEnviadas, setDemosEnviadas] = useState([]);
  const [llamadasMasMinuto, setLlamadasMasMinuto] = useState([]);
  const [llamadasMasTresMinutos, setLlamadasMasTresMinutos] = useState([]);
  const [ventasMes, setVentasMes] = useState([]);
  const [objetivoVentasMes, setObjetivoVentasMes] = useState([]);
  
  // // const audioRef= useRef();

  const usuarioSeleccionado = useSelector((state) => {
    return selectUsuarioSeleccionado(state);
  });
  // const history = useHistory()

  const CardContenido = ({ titulo, descripcion, children }) => {
    return (
      <Paper elevation={1} style={{ padding: 30 }}>
        {/* <Typography variant="h5" style={{ paddingBottom: 5 }}>
          {titulo}
        </Typography>
        <Divider />
        <Box style={{ paddingTop: 20 }}>{children}</Box> */}
        
        <Typography variant="h5" style={{ marginBottom: 0 }}>
          {titulo}
        </Typography>
        <Box style={{marginBottom: 20, color: '#9c9c9c'}}>
          {descripcion}
        </Box>
        <Box style={{ paddingTop: 1 }}>{children}</Box>
      </Paper>
    );
  };
  const CardIndicador = ({ titulo, descripcion, valor }) => {
    return (
      <Paper elevation={1} style={{ padding: 30, display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Typography variant="h5" style={{ paddingBottom: 5 }}>
            {titulo}
          </Typography>
          <Typography variant="body1" style={{ paddingBottom: 5 }}>
            {descripcion}
          </Typography>
        </div>
        <Typography variant="h3" style={{ paddingBottom: 5, fontWeight: 'bolder' }}>
          {valor}
        </Typography>
      </Paper>
    );
  };

  const columnasLeadsComentarios = [
    { field: "id", headerName: "ID",width: 70,  },
    { field: "temperatura_id2", headerName: "", width: 20,filterable: false, renderCell: (item) => {
        return <TemperaturaAlumno temperaturaId={item.row.temperatura_id} showLabel={false}/>
      }
    },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return (
      <Link to={`/alumnos/detalle/${item.row.id}`}>
        {item.row.nombre}
      </Link>
      )
    } },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "oposicion_nombre", headerName: "Oposicion", width: 150 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    { field: "status_nombre", headerName: "Estado", width: 200 },
    { field: "temperatura_nombre", headerName: "Temperatura", width: 200, 
      valueFormatter: (item) => {
        console.log(item)
        return {item}
        //return item.row.temperatura_nombre
      },
      renderCell: (item) => {
        return <TemperaturaAlumno temperaturaId={item.row.temperatura_id} />
      }
    },
    // { field: "es_lead_valido", headerName: "Valido", width: 100,renderCell: (item) => {
    //   return item.row.es_lead_valido === "1" ? 'SI' : 'NO'
    // } },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  const columnasLeadsPrimariaInfantil = [
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link to={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    // { field: "status_nombre", headerName: "Estado", width: 100 },
    { field: "es_lead_valido", headerName: "Valido", width: 100,renderCell: (item) => {
      return item.row.es_lead_valido === "1" ? 'SI' : 'NO'
    } },
    { field: "oposicion_nombre", headerName: "Oposicion", width: 150 },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  const columnasLeadsPlanificados = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "temperatura_id2", headerName: "", width: 20,filterable: false, renderCell: (item) => {
        return <TemperaturaAlumno temperaturaId={item.row.temperatura_id} showLabel={false}/>
      }
    },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link to={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    {
      'field': 'proximo_contacto', 
      'width':200, 
      'headerName': 'Proximo contacto', 
      'description': 'Proximo contacto',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
    },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    { field: "status_nombre", headerName: "Estado", width: 200 },
    { field: "temperatura_nombre", headerName: "Temperatura", width: 200, 
      valueFormatter: (item) => {
        console.log(item)
        return {item}
        //return item.row.temperatura_nombre
      },
      renderCell: (item) => {
        return <TemperaturaAlumno temperaturaId={item.row.temperatura_id} />
      }
    },
    // { field: "es_lead_valido", headerName: "Valido", width: 100,renderCell: (item) => {
    //   return item.row.es_lead_valido === "1" ? 'SI' : 'NO'
    // } },
    { field: "oposicion_nombre", headerName: "Oposicion", width: 150 },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } ,
  },
  
  ]
  const columnasLeadsPendientesPago = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "temperatura_id2", headerName: "", width: 20,filterable: false, renderCell: (item) => {
        return <TemperaturaAlumno temperaturaId={item.row.temperatura_id} showLabel={false}/>
      }
    },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link to={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    {
      'field': 'proximo_contacto', 
      'width':200, 
      'headerName': 'Proximo contacto', 
      'description': 'Proximo contacto',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
    },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    { field: "status_nombre", headerName: "Estado", width: 200 },
    { field: "temperatura_nombre", headerName: "Temperatura", width: 200, 
      valueFormatter: (item) => {
        console.log(item)
        return {item}
        //return item.row.temperatura_nombre
      },
      renderCell: (item) => {
        return <TemperaturaAlumno temperaturaId={item.row.temperatura_id} />
      }
    },
    // { field: "es_lead_valido", headerName: "Valido", width: 100,renderCell: (item) => {
    //   return item.row.es_lead_valido === "1" ? 'SI' : 'NO'
    // } },
    { field: "oposicion_nombre", headerName: "Oposicion", width: 150 },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } ,
  },
  
  ]
  const columnasLeadsSecundaria = [
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link to={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    // { field: "status_nombre", headerName: "Estado", width: 100 },
    { field: "es_lead_valido", headerName: "Valido", width: 100,renderCell: (item) => {
      return item.row.es_lead_valido === "1" ? 'SI' : 'NO'
    } },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  const columnasLeadsValidos = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "temperatura_id2", headerName: "", width: 20,filterable: false, renderCell: (item) => {
        return <TemperaturaAlumno temperaturaId={item.row.temperatura_id} showLabel={false}/>
      }
    },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link to={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    { field: "temperatura_nombre", headerName: "Temperatura", width: 200, 
      valueFormatter: (item) => {
        console.log(item)
        return {item}
        //return item.row.temperatura_nombre
      },
      renderCell: (item) => {
        return <TemperaturaAlumno temperaturaId={item.row.temperatura_id} />
      }
    },
    // { field: "status_nombre", headerName: "Estado", width: 100 },
    // { field: "lead_valido_segundo_nivel", headerName: "Estado V2", width: 100 },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  const columnasVentasMes = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "alumno_nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link to={`/alumnos/detalle/${item.row.alumno_id}`}>{item.row.alumno_nombre}</Link>
    } },
    // { field: "status_nombre", headerName: "Estado", width: 100 },
    // { field: "lead_valido_segundo_nivel", headerName: "Estado V2", width: 100 },
    {
      'field': 'fecha_cobro', 
      'width':200, 
      'headerName': 'Fecha cobro', 
      'description': 'Fecha cobro',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  const columnasLeadsCurso = [
    { field: "id", headerName: "ID" },
    { field: "nombre", headerName: "Nombre", flex: 1, minWidth: 300, renderCell: (item) => {
      return <Link to={`/alumnos/detalle/${item.row.id}`}>{item.row.nombre}</Link>
    } },
    { field: "comunidad_autonoma_nombre", headerName: "Comunidad", width: 200 },
    { field: "especialidad_nombre", headerName: "Especialidad", width: 350 },
    { field: "status_nombre", headerName: "Estado", width: 100 },
    { field: "lead_valido_segundo_nivel", headerName: "Estado V2", width: 100 },
    {
      'field': 'created', 
      'width':200, 
      'headerName': 'Añadido', 
      'description': 'Añadido',
      type: 'date',
      valueFormatter: params => {
        return moment(params?.value).format("DD/MM/YYYY hh:mm A")
      },
      renderCell: (cellValues) => {
        return (
          
            (cellValues.value !== null && cellValues.value !== "0000-00-00 00:00:00") ?
    
            <Moment format="DD/MM/YYYY HH:mm:ss">
              {cellValues.value}
            </Moment>
    
            :
    
            <></>
    
          
        );
      } 
  },
  ]
  /* -------------------------------------------------------------------------- */
  /*                                  UseEffect                                 */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    setLoading(true);
    setLoadingMensaje("Cargando");

    const usuario_id = usuarioSeleccionado.id;

    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_comentarios",
    }).then((response) => {
      setLoading(false);

      response && setLeadsComentarios(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_primaria_infantil",
    }).then((response) => {
      setLoading(false);

      response && setLeadsPrimariaInfantil(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_secundaria",
    }).then((response) => {
      setLoading(false);

      response && setLeadsSecundaria(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_validos",
    }).then((response) => {
      setLoading(false);

      response && setLeadsValidos(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_contactados_hoy",
    }).then((response) => {
      setLoading(false);

      response && setLeadsContactadosHoy(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_planificados",
    }).then((response) => {
      setLoading(false);

      response && setLeadsPlanificados(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_pendientes_pago",
    }).then((response) => {
      setLoading(false);

      response && setLeadsPendientesPago(response.resultado);
    });

    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "llamadas_mas_minuto",
    }).then((response) => {
      setLoading(false);

      response && setLlamadasMasMinuto(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "llamadas_mas_tres_minutos",
    }).then((response) => {
      setLoading(false);

      response && setLlamadasMasTresMinutos(response.resultado);
    });

    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "ventas_mes",
    }).then((response) => {
      setLoading(false);

      response && setVentasMes(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "objetivo_ventas_mes",
    }).then((response) => {
      setLoading(false);

      response && setObjetivoVentasMes(response.resultado);
    });
    
    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "leads_curso",
    }).then((response) => {
      setLoading(false);

      response && setLeadsCurso(response.resultado);
    });

    getPlanificacionLlamadas({
      usuario_id: usuario_id,
      nombre_dato: "demos_enviadas",
    }).then((response) => {
      setLoading(false);

      response && setDemosEnviadas(response.resultado);
    });

    getAlumnosPendientesPagoPrimeraCuota({usuario_id}).then( (response) => {
        response && setAlumnosPendientesPago(response.alumnos)

        setLoading(false)

    })
  }, []);

  const StackAlumnosPendientePago = ({arrayAlumnos}) => {
    return (
    
    <Stack direction="column" spacing={2} style={{marginTop: 10}}>
        {
            arrayAlumnos.map( (item) => {
                return (
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                {item.nombre}
                            </Typography>

                            <Typography variant="body2" component="p">
                                Ultimo pago: {item.fecha_primera_cuota !== '0000-00-00 00:00:00' && item.fecha_primera_cuota !== null ? <Chip style={{backgroundColor: red[100]}} size="small" label={item.fecha_primera_cuota} /> : <Chip color="primary" size="small" label={"No se ha realizado ningún pago"} /> }
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button 
                                size="small"
                                component={Link}
                                to={`alumnos/detalle/${item.id}`}
                            >Abrir ficha</Button>
                        </CardActions>
                    </Card>
                )
            })
        }
    </Stack>

    )
}
  const Contenido = () => {
    const [filterModelLeadsComentarios, onFilterModelChangeLeadsComentarios] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_recibidos_comentarios')
    const [filterModelLeadsPrimariaInfantil, onFilterModelChangeLeadsPrimariaInfantil] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_recibidos_primaria_infantil')
    const [filterModelSecundaria, onFilterModelChangeSecundaria] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_recibidos_secundaria')
    const [filterModelValidos, onFilterModelChangeValidos] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_recibidos_validos')
    const [filterModelPlanificados, onFilterModelChangePlanificados] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_planificados')
    const [filterModelCurso, onFilterModelChangeCurso] = useDataGridFilterPersist(0,'planificacion_llamadas_page','leads_curso')

    return (
      <>
        <Box style={{ marginTop: 85, padding: "0 15px" }}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={6}>
              <CardContenido titulo="Próxima llamada recomendada">
                Contiene la siguiente llamada que recomienda el sistema
              </CardContenido>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                component={Link}
                to="alumnos/create"
              >
                Añadir Lead
              </Button>
              </Grid>
            {/* <Grid item xs={12} md={4}>
              <CardIndicador 
                titulo="Demos enviadas" 
                descripcion="Demos que se han enviado a Leads"
                valor={demosEnviadas} 
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <CardIndicador 
                titulo="Leads contactados hoy" 
                descripcion="Leads en los que se han puesto notas"
                valor={leadsContactadosHoy} 
              />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <CardIndicador 
                titulo="Llamadas de mas de un minuto" 
                descripcion="Llamadas de Ringover de mas de un minuto"
                valor={llamadasMasMinuto} 
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CardIndicador 
                titulo="Llamadas de mas de tres minutos" 
                descripcion="Llamadas de Ringover de mas de tres minutos"
                valor={llamadasMasTresMinutos} 
              />
            </Grid> */}
            <Grid item xs={12} md={6}>
              <CardIndicador 
                titulo="Ventas/Objetivo mes" 
                descripcion="Objetivo propuesto de ventas en el mes en curso"
                valor={`${ventasMes.length}/${objetivoVentasMes}`} 
              />
            </Grid>

            {/* Lead consultas */}
            <Grid item xs={12} md={6}>
              <CardContenido 
                titulo="Leads con consultas"
                descripcion={"Contiene las interacciones que han entrado con alguna duda. (Leads o repetidos que han escrito comentarios o dudas)"}>
                <Box>
                  <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsComentarios}
                      columns={columnasLeadsComentarios}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelLeadsComentarios }
                      onFilterModelChange={ onFilterModelChangeLeadsComentarios }
                    />

                  </div>
                </Box>
              </CardContenido>
            </Grid>

            {/* Leads planificados/agenda */}
            <Grid item xs={12} md={6}>
              <CardContenido 
                titulo="Agenda"
                descripcion={"Leads planificados para hoy y Leads planificados pasados"}>
                <Box>
                  <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsPlanificados}
                      columns={columnasLeadsPlanificados}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelPlanificados }
                      onFilterModelChange={ onFilterModelChangePlanificados }
                    />

                  </div>
                </Box>
              </CardContenido>
            </Grid>

            {/* Leads validos */}
            <Grid item xs={12} md={6}>
              <CardContenido 
                titulo="Leads válidos"
                descripcion={"Contiene los leads válidos"}
              >
                <Box>
                <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsValidos}
                      columns={columnasLeadsValidos}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelValidos }
                      onFilterModelChange={ onFilterModelChangeValidos }
                    />
                  </div>
                  </Box>
              </CardContenido>
            </Grid>
            
            {/* Leads pendientes de pago */}
            <Grid item xs={12} md={3}>
              <CardContenido 
                titulo="Leads pendientes de pago"
                descripcion={"Contiene los leads cuyo estado es pendientes de pago"}
              >
                <Box>
                {/* <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  > */}
                  <StackAlumnosPendientePago arrayAlumnos={alumnosPendientesPago} />

                    {/* <DataGridPro
                      rows={leadsPendientesPago}
                      columns={columnasLeadsPendientesPago}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelValidos }
                      onFilterModelChange={ onFilterModelChangeValidos }
                    /> */}
                  {/* </div> */}
                  </Box>
              </CardContenido>

            </Grid>

            {/* Ventas mes */}
            <Grid item xs={12} md={3}>
              <CardContenido 
                titulo="Ventas contabilizadas este mes"
                descripcion={"Contiene las ventas contabilizadas en este mes"}
              >
                <Box>
                <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={ventasMes}
                      columns={columnasVentasMes}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelValidos }
                      onFilterModelChange={ onFilterModelChangeValidos }
                      columnVisibilityModel={{
                        id: false,
                      }}
                    />
                  </div>
                  </Box>
              </CardContenido>
            </Grid>

            {/* Leads en curso */}
            {/* <Grid item xs={12} md={6}>
              <CardContenido titulo="Leads curso">
              <Box style={{marginBottom: 20}}>
                  Contiene los leads de curso
                </Box>
                <Box>
                <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsCurso}
                      columns={columnasLeadsCurso}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelCurso }
                      onFilterModelChange={ onFilterModelChangeCurso }
                    />
                  </div>
                  </Box>
              </CardContenido>
            </Grid> */}

            {/* Leads de primaria */}
            {/* <Grid item xs={12} md={6}>
              <CardContenido titulo="Leads de primaria e infantil">
              <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsPrimariaInfantil}
                      columns={columnasLeadsPrimariaInfantil}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelLeadsPrimariaInfantil }
                      onFilterModelChange={ onFilterModelChangeLeadsPrimariaInfantil }
                    />

                  </div>
              </CardContenido>
            </Grid> */}

            {/* Leads de secundaria */}
            {/* <Grid item xs={12} md={6}>
              <CardContenido titulo="Leads de secundaria">
              <div
                    style={{ height: 400, width: "100%", padding: "0" }}
                  >
                    <DataGridPro
                      rows={leadsSecundaria}
                      columns={columnasLeadsSecundaria}
                      components={{ Toolbar: () => { 
                          return <div style={{display: 'flex', padding: '0.5px 0.5px',}}>
                          <GridToolbarFilterButton />
                        </div> 
                      }}}
                      filterModel={ filterModelSecundaria }
                      onFilterModelChange={ onFilterModelChangeSecundaria }
                    />
                  </div>
              </CardContenido>
            </Grid> */}
            
          </Grid>
        </Box>

      </>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Return                                   */
  /* -------------------------------------------------------------------------- */
  return (
    <Contenido />
  );
}
