import React from "react";

export default function TagsIcon() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4137 13L14 0.58626C13.815 0.399734 13.5947 0.251852 13.352 0.151209C13.1093 0.0505661 12.849 -0.000829299 12.5862 1.01187e-05H0.999995C0.734778 1.01187e-05 0.480425 0.105367 0.292888 0.292904C0.105352 0.48044 -5.1401e-06 0.734794 -5.1401e-06 1.00001V12.5863C-0.000844558 12.849 0.0505508 13.1093 0.151194 13.352C0.251837 13.5947 0.399719 13.815 0.586245 14L13 26.4138C13.1857 26.5995 13.4062 26.7469 13.6489 26.8474C13.8916 26.948 14.1517 26.9997 14.4144 26.9997C14.6771 26.9997 14.9372 26.948 15.1798 26.8474C15.4225 26.7469 15.643 26.5995 15.8287 26.4138L26.4137 15.8288C26.5995 15.643 26.7469 15.4225 26.8474 15.1799C26.9479 14.9372 26.9997 14.6771 26.9997 14.4144C26.9997 14.1517 26.9479 13.8916 26.8474 13.6489C26.7469 13.4062 26.5995 13.1857 26.4137 13ZM14.4137 25L1.99999 12.5863V2.00001H12.5862L25 14.4138L14.4137 25ZM8 6.50001C8 6.79668 7.91202 7.08669 7.7472 7.33336C7.58238 7.58004 7.34811 7.7723 7.07402 7.88583C6.79993 7.99936 6.49833 8.02907 6.20736 7.97119C5.91639 7.91331 5.64911 7.77045 5.43933 7.56067C5.22956 7.35089 5.08669 7.08362 5.02882 6.79265C4.97094 6.50167 5.00064 6.20007 5.11418 5.92598C5.22771 5.6519 5.41997 5.41763 5.66664 5.25281C5.91331 5.08798 6.20332 5.00001 6.49999 5.00001C6.89782 5.00001 7.27935 5.15805 7.56066 5.43935C7.84196 5.72065 8 6.10219 8 6.50001Z"
        fill="#808080"
      />
    </svg>
  );
}
