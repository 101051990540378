import React from "react";

export default function EstadisticasIcon() {
  return (
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 21C26 21.2652 25.8946 21.5196 25.7071 21.7071C25.5196 21.8946 25.2652 22 25 22H1C0.734784 22 0.48043 21.8946 0.292893 21.7071C0.105357 21.5196 0 21.2652 0 21V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0C1.26522 0 1.51957 0.105357 1.70711 0.292893C1.89464 0.48043 2 0.734784 2 1V12.7962L8.34125 7.25C8.51381 7.09895 8.73304 7.01179 8.96221 7.00315C9.19137 6.9945 9.41655 7.06488 9.6 7.2025L16.9513 12.7162L24.3412 6.25C24.4381 6.15408 24.5536 6.079 24.6806 6.02935C24.8076 5.9797 24.9434 5.95654 25.0796 5.9613C25.2159 5.96605 25.3497 5.99862 25.4729 6.057C25.5961 6.11538 25.7061 6.19833 25.7961 6.30077C25.8861 6.4032 25.9541 6.52296 25.9961 6.65267C26.0381 6.78238 26.0532 6.9193 26.0403 7.05503C26.0274 7.19076 25.987 7.32243 25.9213 7.44194C25.8557 7.56146 25.7664 7.66629 25.6588 7.75L17.6588 14.75C17.4862 14.9011 17.267 14.9882 17.0378 14.9969C16.8086 15.0055 16.5834 14.9351 16.4 14.7975L9.04875 9.28625L2 15.4538V20H25C25.2652 20 25.5196 20.1054 25.7071 20.2929C25.8946 20.4804 26 20.7348 26 21Z"
        fill="#808080"
      />
    </svg>
  );
}
